import { t } from "i18next";
import { Pencil } from "lucide-react";

const dialogUiConfig = {
  deviceNameFieldLabel: t("devicesScreen.updateDeviceNameDialog.deviceNameFieldLabel"),
  deviceNameFieldPlaceholder: t("devicesScreen.updateDeviceNameDialog.deviceNameFieldPlaceholder"),
  deviceNameValidationMsg: t("devicesScreen.updateDeviceNameDialog.deviceNameValidationMsg"),
  createContext: {
    successMsg: t("devicesScreen.updateDeviceNameDialog.createContext.successMsg"),
    errMsg: t("devicesScreen.updateDeviceNameDialog.createContext.errMsg"),
    DialogTriggerIcon: Pencil,
    submitBtnLabel: t("devicesScreen.updateDeviceNameDialog.createContext.submitBtnLabel"),
    dialogTriggerLabel: t("devicesScreen.updateDeviceNameDialog.createContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("devicesScreen.updateDeviceNameDialog.createContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("devicesScreen.updateDeviceNameDialog.createContext.dialogHeaderDescr"),
  },
  updateContext: {
    successMsg: t("devicesScreen.updateDeviceNameDialog.updateContext.successMsg"),
    errMsg: t("devicesScreen.updateDeviceNameDialog.updateContext.errMsg"),
    DialogTriggerIcon: Pencil,
    submitBtnLabel: t("devicesScreen.updateDeviceNameDialog.updateContext.submitBtnLabel"),
    dialogTriggerLabel: t("devicesScreen.updateDeviceNameDialog.updateContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("devicesScreen.updateDeviceNameDialog.updateContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("devicesScreen.updateDeviceNameDialog.updateContext.dialogHeaderDescr"),
  },
};

export default dialogUiConfig;