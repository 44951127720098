import { Button } from "@/components/ui/button";
import { t } from "i18next";
import { MoreHorizontal } from "lucide-react";
import { memo, MouseEventHandler, useContext, useState } from "react";
import UpdateDeviceNameDialog from "@/components/ui/dialog/UpdateDeviceNameDialog";
import { DeviceDataType } from "@/types";
import { UploadCloud, QrCode, CircleX } from "lucide-react";
import { ViewContext } from "components/view/view";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  apiRequestConnectDevice,
  apiRemovePatientDevice,
  apiRequestUpdateDevice,
} from "@/api";

type DeviceActionsDropdownPropsType = {
  deviceData: DeviceDataType;
  disabled?: boolean;
  onSubmitContent: (errMsg: string | null, successMsg?: string) => void;
};

const DeviceActionsDropdown = ({
  deviceData,
  disabled = false,
  onSubmitContent,
}: DeviceActionsDropdownPropsType) => {
  const context = useContext(ViewContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onConnectDeviceClick: MouseEventHandler<HTMLDivElement> = async(event) => {
    event.stopPropagation();
    try {
      const token = await apiRequestConnectDevice(deviceData.id);
      context.qrDialog.show(token);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DropdownMenu
      modal={false}
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
    >
      <DropdownMenuTrigger
        onClick={(e) => e.stopPropagation()}
        asChild
      >
        <Button aria-haspopup="true" size="icon" variant="ghost">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => e.stopPropagation()}
        align="end"
      >
        <DropdownMenuLabel>
          {t("accountScreen.users.table.actions")}
        </DropdownMenuLabel>
        <UpdateDeviceNameDialog
          selectedDeviceData={deviceData}
          disabled={disabled}
          onOpenClose={setDropdownOpen}
          onSubmit={onSubmitContent}
        />
        {!deviceData.is_connected && (
          <DropdownMenuItem
            disabled={disabled}
            onClick={onConnectDeviceClick}
          >
            <div className="flex flex-row items-center w-full h-[32px]">
              <QrCode className="h-4 w-4 mr-[8px]" />
              {/* !FIXME */}
              <span>{t("devicesScreen.actionsDropdown.connectDevice")}</span>
            </div>
          </DropdownMenuItem>
        )}

        <DropdownMenuItem
          disabled={disabled}
          onClick={(event) => {
            event.stopPropagation();
            apiRemovePatientDevice(deviceData.id)
              .then(() => onSubmitContent(null, t("devicesScreen.deviceRemoveSuccess")))
              .catch(() => onSubmitContent(t("devicesScreen.deviceRemoveError")));
          }}
        >
          <div className="flex flex-row items-center w-full h-[32px]">
            <CircleX className="h-4 w-4 mr-[8px]" />
            <span>{t("devicesScreen.actionsDropdown.removeDevice")}</span>
          </div>
        </DropdownMenuItem>
        {deviceData.push_token?.length && (
          <DropdownMenuItem
            disabled={disabled || !deviceData.push_token?.length}
            onClick={(event) => {
              event.stopPropagation();
              apiRequestUpdateDevice(deviceData.id)
                .then(() => onSubmitContent(null, t("devicesScreen.requestDeviceUpdateSuccess")))
                .catch(() => onSubmitContent(t("devicesScreen.requestDeviceUpdateError")));
            }}
          >
            <div className="flex flex-row items-center w-full h-[32px]">
              <UploadCloud className="h-4 w-4 mr-[8px]" />
              <span>{t("devicesScreen.actionsDropdown.requestUpdate")}</span>
            </div>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(DeviceActionsDropdown);