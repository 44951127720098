import { FC, memo, useCallback, useState } from "react";
import { t } from "i18next";
import Axios from "axios";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { MediaContentDataType } from "@/types";
import PlaylistFormSchema from "./PlaylistFormSchema";
import dialogUiConfig from "./dialogUiConfig";
import DialogCommonFooter from "../DialogCommonFooter";
import TagsMultiSelect from "@/components/common/TagsMultiSelect";
import CommonSingleSelect from "../../../common/CommonSingleSelect";
import { parsePlaylistPlaybackRate } from "./helpers";
import BackgroundTrackManager from "./BackgroundTrackManager";

type AddUpdatePlaylistDialogPropsType = {
  onOpenClose: (isOpen: boolean) => void;
  apiPrefix: string;
  parentCategoryId?: string;
  selectedContent?: MediaContentDataType;
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  disabled: boolean;
};

const AddUpdatePlaylistDialog: FC<AddUpdatePlaylistDialogPropsType> = ({
  onOpenClose,
  apiPrefix,
  parentCategoryId,
  selectedContent,
  onSubmit,
  disabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const isUserApi = apiPrefix?.startsWith("/api/user/");

  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<z.infer<typeof PlaylistFormSchema>>({
    resolver: zodResolver(PlaylistFormSchema),
    defaultValues: {
      playlistName: selectedContent?.name ?? "",
      tags: selectedContent?.tags ?? [],
      playbackRate: selectedContent
        ? parsePlaylistPlaybackRate(selectedContent.metadata)
        : 1.0,
      backgroundTrack: selectedContent?.metadata?.background_track,
    },
  });

  const uiConfig = !!selectedContent
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const onSubmitForm = async (formData: z.infer<typeof PlaylistFormSchema>) => {
    try {
      setSubmitFetching(true);

      const { status: playlistResponseStatus } = await Axios({
        method: selectedContent ? "PATCH" : "POST",
        url: !!selectedContent
          ? `${apiPrefix}/category/${
              parentCategoryId ?? "root"
            }/resource/${selectedContent?.id}`
          : `${apiPrefix}/category/${parentCategoryId ?? "root"}/resource`,
        data: {
          description: selectedContent?.description ?? "",
          icon: selectedContent?.icon ?? "",
          preview_url: "", // isn't used
          category_id: parentCategoryId,
          name: formData.playlistName,
          tags: formData.tags,
          media_type: "playlist",
          metadata: {
            playback_rate: formData.playbackRate,
            background_track: formData.backgroundTrack?.length
              ? formData.backgroundTrack
              : undefined,
          },
        },
      });

      if (playlistResponseStatus !== 200 && playlistResponseStatus !== 201) {
        throw new Error("Error creating playlist");
      }

      onOpenChange(false);
      onSubmit(null, uiConfig.submitSuccessMessage);
    } catch (err) {
      console.error(err);
      onSubmit(uiConfig.submitErrorMessage);
    } finally {
      setSubmitFetching(false);
    }
  };

  const avoidDefaultDomBehavior = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  return (
    <Dialog open={dialogOpen} onOpenChange={onOpenChange}>
      <DialogTrigger disabled={disabled} className="p-0 w-full">
        <DropdownMenuItem
          disabled={disabled}
          className="w-full flex py-0 px-[8px]"
          onSelect={(e) => e.preventDefault()}
        >
          <div className="flex flex-row items-center w-full h-[32px]">
            <uiConfig.DialogTriggerIcon className="h-4 w-4 mr-[8px]" />
            <span>
              {isUserApi
                ? uiConfig.dialogTriggerLabelUser
                : uiConfig.dialogTriggerLabelCommon}
            </span>
          </div>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent
        className="flex flex-col w-[432px]"
        onPointerDownOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        onInteractOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
      >
        <DialogHeader>
          <DialogTitle>{uiConfig.dialogHeaderTitle}</DialogTitle>
          <DialogDescription>{uiConfig.dialogHeaderDescr}</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="h-full" onSubmit={form.handleSubmit(onSubmitForm)}>
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="playlistName"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel className="mb-[6px]" htmlFor="idPlaylistName">
                    {dialogUiConfig.nameFieldLabel}
                  </FormLabel>
                  <FormControl id="idPlaylistName">
                    <Input
                      className="focus-visible:ring-0"
                      id="idPlaylistName"
                      placeholder={dialogUiConfig.nameFieldPlaceholder}
                      {...field}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              name="tags"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel className="mb-[6px]" htmlFor="idTags">
                    {dialogUiConfig.tagsFieldLabel}
                  </FormLabel>
                  <FormControl id="idTags">
                    <Controller
                      {...field}
                      render={({ field: { onChange, onBlur, value } }) => (
                        //   <CustomMultiSelect
                        //     onOpenClose={setPopoverOpen}
                        //     placeholder={dialogUiConfig.tagsFieldPlaceholder}
                        //     defaultSelectedValues={value}
                        //     data={staticTagsConfig}
                        //     disabled={submitFetching}
                        //     onChange={onChange}
                        //     onBlur={onBlur}
                        // />
                        <TagsMultiSelect
                          onOpenClose={setPopoverOpen}
                          placeholder={t("mediaLibraryScreen.tagsPlaceholder")}
                          defaultSelectedValues={value}
                          disabled={submitFetching}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              name="playbackRate"
              render={({ field }) => (
                <FormItem className="mb-[4px] w-full">
                  <FormLabel
                    className="mb-[6px] truncate text-[#11203D] font-medium"
                    htmlFor="idPlaybackRate"
                  >
                    {dialogUiConfig.playbackRateFieldLabel}
                  </FormLabel>
                  <FormControl id="idPlaybackRate">
                    <Controller
                      {...field}
                      render={({ field: { onChange, value } }) => (
                        <CommonSingleSelect
                          disabled={submitFetching}
                          defaultValue={value.toFixed(2)}
                          options={dialogUiConfig.playbackRateOptions}
                          onValueChanged={(value) =>
                            onChange(parseFloat(value))
                          }
                        />
                      )}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              name="backgroundTrack"
              render={({ field }) => (
                <FormItem className="mb-[4px] w-full">
                  <FormLabel
                    className="mb-[6px] truncate text-[#11203D] font-medium"
                    htmlFor="idBackgroundTrack"
                  >
                    {dialogUiConfig.backgroundTrackFieldLabel}
                  </FormLabel>
                  <FormControl id="idBackgroundTrack">
                    <Controller
                      {...field}
                      render={({ field: { onChange, value } }) => (
                        <BackgroundTrackManager
                          onSelectedTrackChange={onChange}
                          disabled={submitFetching}
                          selectedTrackValue={value}
                          placeholder={
                            dialogUiConfig.backgroundTrackFieldPlaceholder
                          }
                          options={dialogUiConfig.backgroundTrackOptions}
                        />
                      )}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
          </form>
        </Form>
        <DialogCommonFooter
          isFetching={submitFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddUpdatePlaylistDialog);
