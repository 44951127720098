
import i18n from "i18n";
import Device from "views/devices/device";
import Devices from "views/devices/devices";

const Routes = [
  {
    path: "/devices",
    view: Devices,
    layout: "app",
    permission: "admin",
    title: i18n.t("devicesScreen.title"),
  },
  {
    path: "/devices/:deviceId",
    view: Device,
    layout: "app",
    permission: "admin",
    title: i18n.t("devicesScreen.title"),
  }
];

export default Routes;
