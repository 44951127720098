import React, { FC, memo, useCallback, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { t } from "i18next";
import dialogUiConfig from "./dialogUiConfig";
import DialogCommonFooter from "../DialogCommonFooter";
import DeviceNameFormSchema from "./DeviceNameFormSchema";
import { apiCreateDevice, apiSetPatientDeviceName } from "@/api";
import { DeviceDataType } from "@/types";

export type UpdateDeviceNameDialogPropsType = {
  dialogTriggerBtn?: React.ReactNode, 
  selectedDeviceData?: DeviceDataType, 
  onOpenClose: (isOpen: boolean) => void;
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  disabled?: boolean;
};

const UpdateDeviceNameDialog: FC<UpdateDeviceNameDialogPropsType> = ({
  dialogTriggerBtn,
  selectedDeviceData,
  onOpenClose,
  onSubmit,
  disabled = false,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
 
  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<z.infer<typeof DeviceNameFormSchema>>({
    resolver: zodResolver(DeviceNameFormSchema),
    defaultValues: {
      deviceName: selectedDeviceData?.name || "",
    },
  });

  const uiConfig = !!selectedDeviceData
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const onSubmitForm = async (formData: z.infer<typeof DeviceNameFormSchema>) => {
    try {
      setSubmitFetching(true);
      if (selectedDeviceData) {
        await apiSetPatientDeviceName(selectedDeviceData.id, formData.deviceName);
      } else {
        await apiCreateDevice(formData.deviceName);
      }
      onSubmit(null, uiConfig.successMsg);
      onOpenChange(false);
    } catch (err) {
      console.error(err);
      onSubmit(uiConfig.errMsg);
    } finally {
      setSubmitFetching(false);
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={onOpenChange}
    >
      <DialogTrigger
        disabled={disabled}
        className="p-0 w-full"
      >
        {dialogTriggerBtn ?? (
          <DropdownMenuItem
            disabled={disabled}
            className="w-full flex py-0 px-[8px]"
          >
            <div className="flex flex-row items-center w-full h-[32px]">
              <uiConfig.DialogTriggerIcon className="h-4 w-4 mr-[8px]" />
              <span>{uiConfig.dialogTriggerLabel}</span>
            </div>
          </DropdownMenuItem>
        )}
      </DialogTrigger>
      <DialogContent className="flex flex-col w-[432px]">
        <DialogHeader>
          <DialogTitle>
            {uiConfig.dialogHeaderTitle}
          </DialogTitle>
          <DialogDescription>
            {uiConfig.dialogHeaderDescr}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="h-full"
            onSubmit={form.handleSubmit(onSubmitForm)}
          >
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="deviceName"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel
                    className="mb-[6px]"
                    htmlFor="deviceName"
                  >
                    {dialogUiConfig.deviceNameFieldLabel}
                  </FormLabel>
                  <FormControl id="deviceName">
                    <Input
                      disabled={submitFetching}
                      id="deviceName"
                      className="focus-visible:ring-0"
                      placeholder={dialogUiConfig.deviceNameFieldPlaceholder}
                      {...field}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
          </form>
        </Form>

        <DialogCommonFooter
          isFetching={submitFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(UpdateDeviceNameDialog);
