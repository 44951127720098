import { memo, useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbList } from "@/components/ui/breadcrumb";
import { DeviceDataType } from "@/types";
import { Badge } from "@/components/ui/badge";
import { t } from "i18next";
import { apiGetAllAvailablePatients, apiGetPatientDevice } from "@/api";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";
import { composeDevicesData } from "./helpers";

const Device = () => {
  const params = useParams();
  const [patientDeviceData, setPatientDeviceData] = useState<DeviceDataType|null>(null);
  const [dataFetching, setDataFetching] = useState(false);

  const fetchData = async (deviceId: string) => {
    try {
      setDataFetching(true);

      if (!params?.deviceId) {
        throw new Error("invalid device id");
      }

      const patients = await apiGetAllAvailablePatients();
      const patientDevice = await apiGetPatientDevice(params.deviceId);

      if (patientDevice) {
        const devicesData = composeDevicesData([patientDevice], patients, []);
        setPatientDeviceData(devicesData.at(0) ?? null);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDataFetching(false);
    }
  }

  useEffect(() => {
    if (params.deviceId) fetchData(params.deviceId);
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (dataFetching) {
    return (
      <Loader2 className={cn("h-16 w-16 text-primary/60 animate-spin absolute z-10 top-1/2 left-1/2")} />
    );
  }

  return (
    <div className="sm:px-6 flex flex-col flex-1 pb-2 h-full overflow-hidden w-full">
      <Breadcrumb className="py-5">
        <BreadcrumbList>
          <BreadcrumbItem className="text-2xl font-semibold text-[#11203D]">
            {patientDeviceData?.name || params.deviceId}
            {patientDeviceData && (
              <Badge variant={patientDeviceData.is_online ? "secondary" : "destructive"}>
              {patientDeviceData.is_online
                ? t("accountScreen.users.table.onlineStatus")
                : t("accountScreen.users.table.offlineStatus")}
            </Badge>
            )}
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      {patientDeviceData && (
        <div className="flex flex-col w-full border-2 border-slate-200 mx-2 rounded-md p-2">
          <h3 className="text-lg mb-2 font-extrabold">
            {t("devicesScreen.deviceDetails.generalTitle")}
          </h3>
          <span className="mr-4">
            <b>Device name: </b>
            {patientDeviceData.name?.length ? patientDeviceData.name : "N/A"}
          </span>
          <span className="mr-4"><b>Device id: </b>{patientDeviceData.id}</span>
          <span className="mr-4"><b>Patient name: </b>{patientDeviceData.patient_name}</span>
          <span className="mr-4"><b>Patient id: </b>{patientDeviceData.patient_id}</span>
          <span className="mr-4"><b>Added by: </b>{patientDeviceData.added_by}</span>        
          <span className="mr-4">
            <b>Date created: </b>
            {moment(patientDeviceData.date_created).format("YYYY-MM-DD HH:mm:ss")}
          </span>
          <span className="mr-4">
            <b>Date updated: </b>
            {moment(patientDeviceData.date_updated).format("YYYY-MM-DD HH:mm:ss")}
          </span>
          <span className="mr-4">
            <b>Push token: </b>
            {patientDeviceData.push_token?.length ? patientDeviceData.push_token : "N/A"}
          </span>
        </div>
      )}
      <div className="flex flex-row w-full mt-4">
        {patientDeviceData?.metadata?.app && (
          <div className="flex flex-col w-2/4 border-2 border-slate-200 mx-2 rounded-md p-2">
            <h3 className="text-lg mb-2 font-extrabold">
              {t("devicesScreen.deviceDetails.appMetadataTitle")}
            </h3> 
            <span className="mr-4"><b>version: </b>{patientDeviceData.metadata.app.version}</span>   
            <span className="mr-4"><b>buildNumber: </b>{patientDeviceData.metadata.app.buildNumber}</span>
            <span className="mr-4"><b>runtimeVersion: </b>{patientDeviceData.metadata.app.runtimeVersion}</span>        
            <span className="mr-4"><b>lastUpdateId: </b>{patientDeviceData.metadata.app.lastUpdateId ?? "N/A"}</span>   
            <span className="mr-4">
              <b>updatesChanngel: </b>
              {patientDeviceData.metadata.app.updatesChanngel?.length ? patientDeviceData.metadata.app.updatesChanngel : "N/A"}
            </span>   
          </div>
        )}
        {patientDeviceData?.metadata?.device && (
          <div className="flex flex-col w-2/4 border-2 border-slate-200 mx-2 rounded-md p-2">
            <h3 className="text-lg mb-2 font-extrabold">
            {t("devicesScreen.deviceDetails.deviceMetadataTitle")}
            </h3>
            <span className="mr-4"><b>brand: </b>{patientDeviceData.metadata.device.brand}</span>   
            <span className="mr-4"><b>deviceName: </b>{patientDeviceData.metadata.device.deviceName}</span>
            <span className="mr-4"><b>deviceType: </b>{patientDeviceData.metadata.device.deviceType}</span> 
            <span className="mr-4"><b>totalSpace: </b>{patientDeviceData.metadata.device.totalSpace}</span>   
            <span className="mr-4"><b>freeSpace: </b>{patientDeviceData.metadata.device.freeSpace}</span>   
            <span className="mr-4"><b>isDevice: </b>{patientDeviceData.metadata.device.isDevice ? "YES" : "NO"}</span>   
            <span className="mr-4"><b>modelName: </b>{patientDeviceData.metadata.device.modelName}</span>   
            <span className="mr-4"><b>networkStateType: </b>{patientDeviceData.metadata.device.networkStateType}</span>   
            <span className="mr-4"><b>osBuildId: </b>{patientDeviceData.metadata.device.osBuildId}</span>   
            <span className="mr-4"><b>osVersion: </b>{patientDeviceData.metadata.device.osVersion}</span>   
            <span className="mr-4"><b>totalMemory: </b>{patientDeviceData.metadata.device.totalMemory}</span>   
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Device);