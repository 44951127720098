import CommonSingleSelect, { SelectOptionType } from "@/components/common/CommonSingleSelect";
import { backgroundTracksConf } from "@/config";
import { Loader2, PauseCircle, PlayCircle } from "lucide-react";
import { memo, MouseEventHandler, useRef, useState } from "react";

type BackgroundTrackMangerPropsType = {
  onSelectedTrackChange: (...event: any[]) => void;
  disabled?: boolean;
  selectedTrackValue: string;
  placeholder?: string;
  options: SelectOptionType<string>[],
};

const BackgroundTrackManager = ({
  onSelectedTrackChange,
  disabled = false,
  selectedTrackValue,
  placeholder = "",
  options,
}: BackgroundTrackMangerPropsType) => {
  const audioPlayerRef = useRef<HTMLAudioElement|null>(null); // ElementRef<"audio">
  const [isBgAudioPlaying, setIsBgAudioPlaying] = useState<boolean>(false);
  const [isBgAudioLoading, setIsBgAudioLoading] = useState<boolean>(false);
  
  const onResetSelectedValue = () => {
    // https://github.com/react-hook-form/react-hook-form/issues/2237
    onSelectedTrackChange("");
    audioPlayerRef.current?.pause();
  };

  const onSelectedValueChanged = (newSelectedTrackValue: string) => {
    audioPlayerRef.current?.pause();
    audioPlayerRef.current?.load();
    setIsBgAudioPlaying(false);
    onSelectedTrackChange(newSelectedTrackValue);
  };

  const onPlayPauseButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      if (!selectedTrackValue) {
        return;
      }

      if (!audioPlayerRef.current?.paused) {
        audioPlayerRef.current?.pause();
      } else {
        audioPlayerRef.current?.play();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renderPlayerControl = () => {
    if (!selectedTrackValue) {
      return null;
    }

    if (isBgAudioLoading) {
      return (
        <Loader2 className="h-10 w-10 text-primary/60 animate-spin" />
      );
    }

    const PlayerControl = isBgAudioPlaying ? PauseCircle : PlayCircle;

    return (
      <button
        disabled={!selectedTrackValue}
        onClick={onPlayPauseButtonClick}
      >
        <PlayerControl
          strokeWidth={1}
          size={36}
          color={!selectedTrackValue ? "#E9E9E9" : "#858992"}
        />
      </button>
    );
  };

  return (
    <div className="flex flex-row items-center">
      <CommonSingleSelect
        onResetValue={onResetSelectedValue}
        placeholder={placeholder}
        disabled={isBgAudioLoading || disabled}
        value={selectedTrackValue}
        options={options}
        onValueChanged={onSelectedValueChanged}
      />
      <div className="h-10 w-12 items-center flex justify-center overflow-hidden ml-2">
        {renderPlayerControl()}
      </div>
      <audio
        ref={audioPlayerRef}
        preload="metadata"
        onLoadStart={() => setIsBgAudioLoading(true)}
        onCanPlay={() => setIsBgAudioLoading(false)}
        onError={() => setIsBgAudioLoading(false)}
        onPlaying={() => setIsBgAudioPlaying(true)}
        onPause={() => setIsBgAudioPlaying(false)}
      >
        <source
          src={selectedTrackValue && backgroundTracksConf[selectedTrackValue]}
          type="audio/mpeg"
        />
      </audio>
    </div>
  );
};

export default memo(BackgroundTrackManager)