import { Breadcrumb, BreadcrumbItem, BreadcrumbList } from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { DataTable } from "@/components/ui/data-table";
import { t } from "i18next";
import { Loader2, PlusCircle } from "lucide-react";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import moment from "moment";
import { composeDevicesData, renderColumnHeader } from "./helpers";
import DeviceActionsDropdown from "./DeviceActionsDropdown";
import { useToast } from "@/components/ui/use-toast"; 
import { apiGetAccountUsers, apiGetAllAvailablePatients, apiGetPatientsDevices } from "@/api";
import { cn } from "@/lib/utils";
import { DeviceDataType } from "@/types";
import UpdateDeviceNameDialog from "@/components/ui/dialog/UpdateDeviceNameDialog";
import dialogUiConfig from "@/components/ui/dialog/UpdateDeviceNameDialog/dialogUiConfig";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";

const Devices = () => {
  const [patientDevicesData, setPatientDevicesData] = useState<DeviceDataType[]>([]);
  const [dataFetching, setDataFetching] = useState(false);
  const [excludeAutoRegistered, setExcludeAutoRegistered] = useState<boolean>(true);
  const navigate = useNavigate();
  const { toast } = useToast();

  const fetchData = useCallback(async () => {
    try {
      setDataFetching(true);

      const patients = await apiGetAllAvailablePatients();
      const patientsDevices = await apiGetPatientsDevices();
      const accountUsers = await apiGetAccountUsers();

      setPatientDevicesData(composeDevicesData(patientsDevices, patients, accountUsers));
    } catch (err) {
      console.error(err);
    } finally {
      setDataFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeviceRowClick = useCallback((deviceData: DeviceDataType) => {
    navigate(`/devices/${deviceData.id}`);
  }, [navigate]);

  const onDevicesDataUpdated = useCallback(async (errMsg: string | null, successMsg?: string) => {
    if (successMsg) {
      toast({ title: successMsg });
      await fetchData();
    } else {
      toast({
        title: errMsg ?? "",
        variant: "destructive",
      });
    }
  }, [fetchData, toast]);

  return (
    <div className="sm:px-6 flex flex-col flex-1 pb-2 h-full overflow-hidden">
      {dataFetching && (
        <Loader2 className={cn("h-16 w-16 text-primary/60 animate-spin absolute z-10 top-1/2 left-1/2")} />
      )}
      <Breadcrumb className="py-5">
        <BreadcrumbList>
          <BreadcrumbItem className="text-2xl font-semibold text-[#11203D]">
            {t("devicesScreen.title")}
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <DataTable
        defaultFilterState={excludeAutoRegistered ? [{ id: "created_by", value: t("devicesScreen.table.createdAuto")}] : []}
        actionButtons={
          <React.Fragment>
            <div className="mr-8 flex flex-1 flex-row items-center">
              <Checkbox
                onClick={() => setExcludeAutoRegistered((prev) => !prev)}
                disabled={dataFetching}
                id="idExclude"
                className="focus-visible:ring-0 mr-2 border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0]"
                checked={excludeAutoRegistered}
                aria-label="Exclude"
              />
              <Label
                htmlFor="idExclude"
                className="truncate w-full peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer font-normal text-sm text-[#11203D] border-0"
              >
                {t("devicesScreen.table.excludeAuto")}
              </Label>
            </div>
          <UpdateDeviceNameDialog
            dialogTriggerBtn={
              <Button className="gap-1">
                <PlusCircle className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    {dialogUiConfig.createContext.dialogTriggerLabel}
                </span>
              </Button>
            }
            selectedDeviceData={undefined}
            disabled={dataFetching}
            onOpenClose={() => {}}
            onSubmit={onDevicesDataUpdated}
          />
          </React.Fragment>
        }
        defaultSortingState={[{ id: "name", desc: false }]}
        stickyHeader
        columns={[
          {
            accessorKey: "name",
            header: renderColumnHeader,
          },
          {
            accessorKey: "date_created",
            header: renderColumnHeader,
            sortingFn: "datetime",
            cell: ({ row: { original } }: {row: { original: DeviceDataType} }) => {
              return moment(original.date_created).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            accessorKey: "created_by",
            header: renderColumnHeader,
            enableColumnFilter: true,
            filterFn: (row, columnId: string, filterValue: string) => {
              const rowData = row.original as unknown as DeviceDataType;
              return rowData[columnId as keyof DeviceDataType] !== filterValue;              
            },
          },
          {
            accessorKey: "added_by",
            header: renderColumnHeader,
          },
          {
            accessorKey: "patient_name",
            header: renderColumnHeader,
            cell: ({ row: { original } }: { row: { original: DeviceDataType} }) => {
              return original.patient_name !== "N/A"
                ? <Button
                    variant="link"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(`/patients/${original.patient_id}`, { state: {}});
                    }}
                  >
                    {original.patient_name}
                  </Button>
                : original.patient_name;
            }
          },
          {
            accessorKey: "app_version",
            header: renderColumnHeader,
          },
          {
            accessorKey: "is_online",
            header: renderColumnHeader,
            cell: ({ row: { original } }: { row: { original: DeviceDataType} }) => {
              if (!original.is_connected || !original.patient_id) {
                return "N/A";
              }

              return (
                <Badge variant={original.is_online ? "secondary" : "destructive"}>
                  {original.is_online
                    ? t("accountScreen.users.table.onlineStatus")
                    : t("accountScreen.users.table.offlineStatus")}
                </Badge>
              );
            },
          },
          {
            id: "actions",
            enableHiding: false,
            cell: ({ row: { original } }: { row: { original: DeviceDataType } }) => (
              <DeviceActionsDropdown
                onSubmitContent={onDevicesDataUpdated}
                deviceData={original}
                disabled={dataFetching}
              />
            )
          }
        ]}
        data={patientDevicesData as any}
        searchField="name"
        onRowClick={onDeviceRowClick}
      />
    </div>
  )
};

export default memo(Devices);